import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import LoadingOverlay from "react-loading-overlay";
import useAxiosPrivate from "hooks/useAxiosPrivate";
//Import Breadcrumb
import ContactForm from "./forms/ContactForm";
import PartnerForm from "./forms/PartnerForm";
import PaymentForm from "./forms/PaymentForm";
import usePartner from "hooks/usePartner";
const EditPartner = () => {
  const axiosApi = useAxiosPrivate();
  const { wizardLoading, setWizardLoading, existData, setExistData } = usePartner();
  const [activeTab, setactiveTab] = useState(1);
  const { id } = useParams();
  const getPartner = async () => {
    try {

      const response = await axiosApi.get('/api/admin/partner-configurations/' + id);
      // const response = {
      //   "status": "success",
      //   "message": "success",
      //   "result": {
      //     "partner": {
      //       "partner_type": ["volume_partner", "user_partner", "location_partner"],
      //       "partner_account": "Free text",
      //       "language": "ms",
      //       "partner_volume": {
      //         "user_id": "6423fd5e3e7db045236d9a19",
      //         "is_fixed_price": true,
      //         "fixed_price": 6500,
      //         "direct_payment_enabled": true,
      //         "expected_volume": "80.5"
      //       },
      //       "partner_user": {
      //         "user_id": "abc123",
      //         "type": "Field Agent",
      //         "user_partnership_share": 35,
      //         "referral_ids": [
      //           "66d1318b69deca1ccee21955",
      //           "668e281016cd54e05e6d894f"
      //         ]
      //       },
      //       "partner_location": {
      //         "box_ids": [
      //           "64377053f5d37f78ac4632a0",
      //           "642bd5680e3ab00d2d29d6a6"
      //         ],
      //         "compensation": ["fixed", "revenue_share",],
      //         "fixed_monthly_price": "6500",
      //         "share_tier": {
      //           "share_tier_1": {
      //             "min": "0",
      //             "max": "0"
      //           },
      //           "share_tier_2": {
      //             "min": "0",
      //             "max": "2.5"
      //           },
      //           "share_tier_3": {
      //             "min": "2.5",
      //             "max": "5"
      //           },
      //           "share_tier_4": {
      //             "min": "5",
      //             "max": "10"
      //           }
      //         }
      //       }
      //     },
      //     "contact": {
      //       "entity_type": "government_organization",
      //       "sector_type": "restaurant",
      //       "legal_name": "Free text",
      //       "contact_name": "Free text",
      //       "contact_address": "Free text",
      //       "contact_city": "Kabupaten Buol",
      //       "contact_country": "Indonesia",
      //       "contact_region": "Sulawesi Tengah",
      //       "contact_postalcode": "21521",
      //       "contact_email": "monthl@gmail.com",
      //       "contact_phone": "Free text",
      //       "sustainability_report_email": "monthl@gmail.com",
      //       "monthly_report_email": "monthl@gmail.com"
      //     },
      //     "payment": {
      //       "bank_account_number": "12512",
      //       "bank_account_name": "accoutn bank name",
      //       "bank_name": "6566b363aa1e6357fc8cf89b",
      //     }
      //   }
      // }
      setExistData(response.data.result)



    } catch (error) {
      console.log(error);
    }
    setWizardLoading(false)

  }
  useEffect(() => {
    setWizardLoading(true)
    setTimeout(() => {
      getPartner();
    }, 3000);

  }, []);


  document.title = `${process.env.REACT_APP_NAME || ''} | Edit Partner`;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={12} sm={12}>
                <h6 className="page-title">Edit Partner</h6>
                <ol className="breadcrumb mb-0"><li className="breadcrumb-item"><a href="/">Partners</a></li><li className="breadcrumb-item"><a href="/partner-overview">Overview</a></li><li className="active breadcrumb-item" aria-current="page">Edit Partner</li></ol>
              </Col>
            </Row>
          </div>
          <LoadingOverlay
            active={wizardLoading}
            spinner
            text='Processing...'
          >
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}>
                            <NavLink
                              className={classnames({ current: activeTab === 1 })}
                              onClick={() => {
                                // setactiveTab(1);
                              }}
                            >
                              <span className="number">1</span>{" "}
                              Partner Information
                            </NavLink>
                          </NavItem>
                          <NavItem className={classnames({ current: activeTab === 2 })}>
                            <NavLink
                              className={classnames({ active: activeTab === 2 })}
                              onClick={() => {
                                // setactiveTab(2);
                              }}
                            >
                              <span className="number">2</span>{" "}
                              Partnership Information
                            </NavLink>
                          </NavItem>
                          <NavItem className={classnames({ current: activeTab === 3 })}>
                            <NavLink
                              className={classnames({ active: activeTab === 3 })}
                              onClick={() => {
                                // setactiveTab(3);
                              }}
                            >
                              <span className="number">3</span>
                              Payment Sharing Settings
                            </NavLink>
                          </NavItem>
                          {/* <NavItem className={classnames({ current: activeTab === 4 })}>
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={() => {
                              setactiveTab(4);
                            }}
                          >
                            <span className="number">4</span>
                            Submit Form
                          </NavLink>
                        </NavItem>
                         */}
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <TabContent
                          activeTab={activeTab}
                          className="body"
                        >
                          <TabPane tabId={1}>
                            <ContactForm activeTab={activeTab} setactiveTab={setactiveTab} existData={existData} />
                          </TabPane>
                          <TabPane tabId={2}>
                            <PartnerForm activeTab={activeTab} setactiveTab={setactiveTab} existData={existData} id={id} />
                          </TabPane>
                          <TabPane tabId={3}>
                            <PaymentForm activeTab={activeTab} setactiveTab={setactiveTab} existData={existData} id={id} />
                          </TabPane>
                        </TabContent>
                      </div>
                      {/* <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab - 1);
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        <li
                          className={activeTab === 4 ? "next disabled" : "next"}
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab + 1);
                            }}
                          >
                            Next
                          </Link>
                        </li>
                      </ul>
                    </div> */}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </LoadingOverlay>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditPartner;