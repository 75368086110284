import React, { useEffect, useState } from "react";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Button,
  FormFeedback,
  Form,
  Input,
  Row,
  Label,
} from "reactstrap";
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import usePartner from "hooks/usePartner";
import { Dropdown } from 'primereact/dropdown';

const PaymentSettingForm = ({ activeTab, setactiveTab, existData = null, id = null }) => {
  const axiosApi = useAxiosPrivate();
  const { partnerForm, contactForm, setWizardLoading } = usePartner();
  const navigate = useNavigate();
  const { showToast } = useAuth();
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
      }
    }
  }
  const [selectedBank, setSelectedBank] = useState('');
  const [bankList, setBankList] = useState([]);

  const changeCountry = (e) => {
    setSelectedCountry(e.target.value)
    getRegions({ id: e.target.value.id });
  }
  const getCountries = async () => {
    try {
      const response = await axiosApi.get(`/api/erp/master/location-countries`);
      var dataList = response.data.results;
      let allData = []
      const selectedData = existData?.payment?.country || "";

      if (dataList.length > 0) {
        dataList.forEach((element, index) => {
          if (element.name == selectedData) {
            setSelectedCountry(element)
            // getRegions({ id: element.id });
          } else if (index == 0) {
            setSelectedCountry(element)
            // getRegions({ id: element.id });
          }
          allData.push(element);
        });
      }
      else {
        setSelectedCountry(null)
        // getRegions({ id: null });
      }
      setCountryList(allData);
    } catch (error) {
      console.log(error);
    }
  }
  const getBankList = async () => {
    setSelectedBank("")
    try {
      let selectedData = "";
      if (partnerForm?.partner_volume) {
        const response = await axiosApi.get(`/api/admin/support/userPaymentMethod/${partnerForm?.partner_volume?.user_id}`);
        let data = response.data.result;
        const bankTransferData = data.find(item => item.method === "banktrf");
        if (bankTransferData) {
          selectedData = bankTransferData?.type
        } else {
          selectedData = ""
        }
      }

      const response = await axiosApi.get(`/api/admin/support/bank-list`);
      var banks = []
      selectedData = existData?.payment?.bank_name || selectedData || "";

      if (response)
        if (response.data.result.length > 0) {
          response.data.result.forEach((element, index) => {
            var bank = {
              value: element._id,
              label: element.name
            }
            if (selectedData == element._id || selectedData == element.name) {
              setSelectedBank(bank)
            }
            banks.push(bank)
          })
        }
      setBankList(banks)

    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getCountries();
    getBankList();
  }, [existData, partnerForm])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      'bank_account_number': existData?.payment?.bank_account_number,
      'bank_account_name': existData?.payment?.bank_account_name,
    },
    validationSchema: Yup.object().shape({
      // bank_account_number: Yup.string().required("This value is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setWizardLoading(true);

      try {
        const opts = { ...values, bank_name: selectedBank.value, country: selectedCountry?.name };
        const final_data = {
          partner: partnerForm,
          contact: contactForm,
          payment: opts
        }
        let response;
        if (id) {
          response = await axiosApi.put('/api/admin/partner-configurations/' + id, final_data);
        } else {
          response = await axiosApi.post('/api/admin/partner-wizard/save', final_data);
        }
        showToast(response.data);
        setTimeout(() => {
          setWizardLoading(false)
          navigate('/partner-overview');
        }, 2000);
      } catch (error) {

        setWizardLoading(false)
        if (error.response && error.response.data && error.response.data.state) {
          showToast(error.response.data);
          validation.setErrors(error.response.data);
        } else {
          let response = {};
          response.state = "error";
          response.toast = true;
          response.message = "Internal Server Error"
          showToast(response);
        }
      }
    },
  });
  return (
    <React.Fragment>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        {/* Country */}
        <Row className="mb-3 ">
          <Label className="col-lg-3 col-md-3 col-form-label">Country</Label>
          <div className="col-lg-5 col-md-5">
            <Dropdown
              disabled
              value={selectedCountry || ''}
              onChange={changeCountry}
              options={countryList}
              optionLabel="name"
              className="h-1 payment-status-dropdown"
            />
          </div>
        </Row>
        <Row className="mb-3">
          <Label className="col-lg-3 col-md-3 col-form-label">Bank Name</Label>
          <div className="col-lg-3 col-md-3">
            <Select
              name={`bank_name`}
              value={selectedBank || ""}
              onChange={value => {
                setSelectedBank(value)
              }}
              options={bankList}
              optionLabel="label"
              classNamePrefix="select2-selection"
            />
          </div>
        </Row>
        <Row className="mb-3">
          <Label className="col-lg-3 col-md-3 col-form-label">Bank Account Number</Label>
          <div className="col-lg-3 col-md-3">
            <Input
              name="bank_account_number"
              placeholder="Enter Account Number"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.bank_account_number || ""}
              invalid={
                validation.touched.bank_account_number && validation.errors.bank_account_number ? true : false
              }
            />
            {validation.touched.bank_account_number && validation.errors.bank_account_number ? (
              <FormFeedback type="invalid">{validation.errors.bank_account_number}</FormFeedback>
            ) : null}
          </div>
        </Row>
        <Row className="mb-3">
          <Label className="col-lg-3 col-md-3 col-form-label">Bank Account Name</Label>
          <div className="col-lg-3 col-md-3">
            <Input
              name="bank_account_name"
              placeholder="Enter Account Name"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.bank_account_name || ""}
              invalid={
                validation.touched.bank_account_name && validation.errors.bank_account_name ? true : false
              }
            />
            {validation.touched.bank_account_name && validation.errors.bank_account_name ? (
              <FormFeedback type="invalid">{validation.errors.bank_account_name}</FormFeedback>
            ) : null}
          </div>
        </Row>
        <div className="d-flex flex-wrap gap-2 justify-content-end">
          <Button
            color="primary"
            onClick={() => toggleTab(activeTab - 1)}
          >
            Previous
          </Button>{" "}
          <Button
            type="submit"
            color="primary"
            disabled={validation.isSubmitting}
          >
            Submit
          </Button>{" "}
        </div>
      </Form>
    </React.Fragment>
  );
};

export default PaymentSettingForm;
