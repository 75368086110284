import { createContext, useState } from "react";

const PartnerContext = createContext({});

export const PartnerProvider = ({ children }) => {
    const [partnerRefresh, setPartnerRefresh] = useState('');
    const [partnerLoading, setPartnerLoading] = useState(false);
    const [wizardLoading, setWizardLoading] = useState(false);
    const [overviews, setOverviews] = useState(null);
    const [overviewTotalRecords, setOverviewTotalRecords] = useState(0);
    const [partnerForm, setPartnerForm] = useState(null);
    const [contactForm, setContactForm] = useState(null);
    const [existData, setExistData] = useState(null);
    const [dataTable, setDataTable] = useState(null);
    const [dataLoading, setDataLoading] = useState(false);
    const [dataTotalRecords, setDataTotalRecords] = useState(0);
    const sectorList = [
        { name: "Individual", value: "individual" },
        { name: "Street Vendor", value: "street_vendor" },
        { name: "Coffee Shop", value: "coffee_shop" },
        { name: "Restaurant", value: "restaurant" },
        { name: "Restaurant Chain", value: "restaurant_chain" },
        { name: "Central Kitchen/Catering", value: "central_kitchen/catering" },
        { name: "Hotel", value: "hotel" },
        { name: "Hotel Chain", value: "hotel_chain" },
        { name: "Elementary School", value: "elementary_school" },
        { name: "High School", value: "high_school" },
        { name: "University", value: "university" },
        { name: "UCO Value Chain", value: "uco_value_chain" },
        { name: "Bank", value: "bank" },
        { name: "Waste Management", value: "waste_management" },
        { name: "Logistics", value: "logistics" },
        { name: "Energy", value: "energy" },
        { name: "Retail", value: "retail" },
        { name: "Trading", value: "trading" },
        { name: "Gym/Sport Facility", value: "gym/sport_facility" },
        { name: "Entertainment/Playground/Cinema", value: "entertainment/playground/cinema" },
        { name: "Other", value: "other" },
    ];

    const entityList = [
        { name: "Corporation", value: "corporation",abbr:"Corp" },
        { name: "Individual", value: "individual", abbr:"Indv" },
        { name: "Government Organization", value: "government_organization", abbr:"Gov" },
        { name: "Non-Governmental Organization", value: "non_governmental_organization", abbr:"NGO" },
        { name: "Place of Business", value: "place_of_business", abbr:"POB" },
        { name: "Influencer", value: "Influencer", abbr:"Influencer" },
        { name: "Field Agent", value: "Field Agent", abbr:"Field Agent" },
        // { name: "Corporate", value: "Corporate", abbr:"Corporate" },

    ];
    return (
        <PartnerContext.Provider value={{ partnerRefresh, setPartnerRefresh, partnerLoading, setPartnerLoading, overviews, setOverviews, overviewTotalRecords, setOverviewTotalRecords, partnerForm, setPartnerForm, contactForm, setContactForm, wizardLoading, setWizardLoading, existData, setExistData, dataTable, setDataTable, dataLoading, setDataLoading, dataTotalRecords, setDataTotalRecords, sectorList, entityList }}>
            {children}
        </PartnerContext.Provider>
    )
}

export default PartnerContext;