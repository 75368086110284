import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Row } from "reactstrap";

import "chartist/dist/scss/chartist.scss";
import { Calendar } from 'primereact/calendar';
import PaymentPeriodModal from "pages/PaymentPeriodModal";
import { formatTimeToUTC } from "helpers/functions";
//i18n
import { withTranslation } from "react-i18next";
import ReportsTable from './ReportTable';
import { useLocation } from "react-router-dom";
import usePartner from "hooks/usePartner";
import useAxiosPrivate from "hooks/useAxiosPrivate";

const Reports = () => {
  const axiosApi = useAxiosPrivate();
  const { partnerRefresh, setDataLoading, setDataTable, setDataTotalRecords } = usePartner();
  const location = useLocation();
  const propsData = location.state;
  const oneMonthBefore = new Date();
  oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1);

  const [filterPeriod, setFilterPeriod] = useState(new Date(oneMonthBefore.getFullYear(), oneMonthBefore.getMonth(), 1))
  const parseStartEndDate = (inp) => {
    if (!inp) return {
      startDate: '',
      endDate: '',
    };
    if (!inp) return '';

    const date = new Date(inp.toString());
    date.setHours(0, 0, 0, 0);

    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    lastDay.setHours(23, 59, 59, 999);

    const formattedFirstDay = formatTimeToUTC(firstDay);
    const formattedLastDay = formatTimeToUTC(lastDay);
    return {
      startDate: formattedFirstDay,
      endDate: formattedLastDay,
    };
  };
  const { startDate, endDate } = parseStartEndDate(oneMonthBefore);

  const parseDate = (inp) => {
    if (!inp) return '';

    const date = new Date(inp.toString());

    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two digits
    const year = date.getFullYear();
    const formatPeriod = year + '-' + month;
    return formatPeriod;
  };
  const [lazyState, setlazyState] = useState({
    searchText: propsData || '',
    startDate: startDate,
    endDate: endDate,
    period: '',
    config: {
      first: 0,
      rows: 25,
      page: 0,
      filters: {
        name: { value: '', matchMode: 'contains' },
      }
    }
  });

  const getReports = async () => {
    setDataLoading(true)
    try {
      const period = parseDate(filterPeriod);

      let queryParams = `searchText=${lazyState.searchText}&period=${period}`;
      const response = await axiosApi.get(`/api/admin/partner-reports?${queryParams}`);

      const result = response.data.result;
      setDataTable(result);
      if (response.data.totalRecords !== null && response.data.totalRecords !== undefined) {
        setDataTotalRecords(response.data.totalRecords)
      } else {
        setDataTotalRecords(0)
      }
    } catch (error) {
      console.log(error);
    }
    setDataLoading(false)
  }

  const changePeriod = (e) => {

    let value = e.value ? e.value : oneMonthBefore;
    setFilterPeriod(value);

    const { startDate, endDate } = parseStartEndDate(e.value);
    let _filters = { ...lazyState };
    _filters.startDate = startDate;
    _filters.endDate = endDate;
    setlazyState(_filters)
  }

  useEffect(() => {
    getReports();
  }, [lazyState, partnerRefresh, filterPeriod]);

  document.title = `${process.env.REACT_APP_NAME || ''} | Partner Reports`;
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {/*<Breadcrumbs title="Support" breadcrumbItem="Reports" />*/}
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8} sm={12}>
                <h6 className="page-title">Reports</h6>
                <ol className="breadcrumb mb-0"><li className="breadcrumb-item"><a href="/">Partners</a></li><li className="breadcrumb-item"><a href="/partner-overview">Reports</a></li><li className="active breadcrumb-item" aria-current="page">    {filterPeriod?.toLocaleDateString("en-US", { month: "short", year: "numeric" }) || ""}</li></ol>
              </Col>
            </Row>
          </div>
          <div className="card">
            <ReportsTable id={propsData} maxDate={oneMonthBefore} filterPeriod={filterPeriod} setFilterPeriod={setFilterPeriod} lazyState={lazyState} setlazyState={setlazyState} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Reports.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Reports);
